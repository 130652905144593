import React, { Component } from "react";
import { Row, Col } from "react-flexbox-grid";
import { withRouter } from "react-router-dom";
import { Input } from "@hbsis.uikit/react";
import { SelectBox, Button } from "components/uikit-adapter/index"
import PropTypes from "prop-types";
import DeadlineControl from "components/deadline-control";
import SuggestionsListHeader from "./../suggestions-list-header";
import StyledSuggestionsListItem from "./../suggestions-list-item/suggestions-list-item.styled";
import IconTruckRed from "images/icn-delivery-truck-red.svg";
import IconSaveWhite from "images/icn-save-white.svg";
import IconCancelWhite from "images/cancel-white.svg";
import PdfFileIcon from "components/icons/pdf-file.icon";
import intl from "react-intl-universal";
import Session from "utils/user-storage";
import * as S from "./styled";
import SuggestionsListHeaderContent from "./components/suggestions-list-header-content";
import { usuarioPodeCancelarPedido } from "../../../services/pedidos/permissao-cancelamento-pedidos";
import { StatusPedido } from "../../../models/pedidos/statusPedido";
import { ButtonLoadCompositionPlus } from "../../button-load-composition-plus";
import { profileAdminOrMasterData } from "services/permissions/getPermissions";
import InputNumber from "../../input-number";
import { showDeliveryTime } from "utils/delivery-time";
import { isLoadCompositionFlowSupplierSide, isScheduleAgreementFlow } from 'utils/validations-general-settings/index'
import { formatNumber } from "utils/format";
import IconTruck from "components/icons/icn-delivery-truck.icon";
import IconCalendarScheduleRefresh from "components/icons/icn-calendar-refresh.icon";
import IconRedirecionamentoPedidoTransferencia from "components/icons/icn-redirect-transferencia.icon";
import { Incoterms } from 'models/incoterm/incoterms';
import {
  mapperItemDataForLoadCompositionModal,
  isDisableChangeOrderButton,
} from "./utils";
import { isValidToShowInvoicesPdfFile } from "utils/invoices-validations";

export const PERFIS_ACESSO = {
  PERFIL_PCI_SOLUTIONS: 1,
  PERFIL_MASTERDATA: 2,
  PERFIL_FORNECEDOR: 3,
  PERFIL_PCP_UNIDADE: 4,
  PERFIL_GESTOR_SOLUTIONS: 5,
};

export const PERFIL_USUARIO_AMBEV = 1;
export const PERFIL_USUARIO_FORNECEDOR = 2;

const PEDIDO_TRANSFERENCIA = "PT";
const TIPO_UNIDADE_NEGOCIO_AMBEV = 1;

export const STATUS_VOLUME = {
  VOLUME_ENVIADO_PARA_TRANSPORTE: 7,
  VOLUME_AGENDADO_PARA_ENVIAR_PARA_TRANSPORTE: 8,
};

const TipoNegociacaoPedido = {
  RenegociacaoDePedidoParaAtenderDemanda: 47,
  EmNegociacaoComposicaoCarga: 10,
  InboundDelivery: 57,
  OrderInTransit: 63
}

const OrderStatusThatDisableLoadCompPlus = {
  Finished: 3,
  Canceled: 4,
  OrderRequestInTransit: 8,
  AwaitingUnloading: 9,
  AwaitingInvoiceEntry: 10,
  AwaitingPayment: 11,
  AwaitingPaymentConfirmation: 12,
}

class OrderListItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      itemsExpanded: props.itemExpanded,
      checked: false,
      incotermOptions: Incoterms,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      JSON.stringify(this.props) !== JSON.stringify(nextProps) ||
      JSON.stringify(this.state) !== JSON.stringify(nextState)
    );
  }

  checkItem = (event) => {
    this.setState(prevState => ({
        checked: !prevState.checked,
      }),
      () => {
        this.props.checkItem(this.props.item, this.state.checked);
      }
    );
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.expanded !== undefined &&
      nextProps.expanded !== this.props.expanded
    ) {
      this.setState({
        itemsExpanded: nextProps.expanded,
      });
    }

    if (
      nextProps.checked !== undefined &&
      nextProps.checked !== this.props.checked
    ) {
      this.setState({
        checked: nextProps.checked,
      });
    }

    if (
      nextProps.unCheckItem !== this.props.unCheckItem &&
      nextProps.unCheckItem
    ) {
      this.setState({
        checked: false,
      });
    }
  }

  expandItems = (eventTarget) => {
    this.setState(prevState => ({
        itemsExpanded: !prevState.itemsExpanded,
      }),
      () =>
        this.props.expandItem(
          eventTarget,
          this.props.item,
          this.state.itemsExpanded,
          this.props.isOrder || this.props.isOrderBulk,
          this.props.isOrderBulk,
          this.props.itemIndex
        )
    );
  };

  goToComposition = (item) => {
    this.props.history.push(`/composicao/${item.Id}`);
  };

  openNewTabPdfPage = (sapOrderCode) => {
    window.open(`/orders/${sapOrderCode}/invoices`);
  };

  goToFamiliaRotuladaCentro = (idFamiliaRotuladaCentro, idPedido) => {
    this.props.history.push(
      `/stocksDetail/${idFamiliaRotuladaCentro}/${idPedido}/P`
    );
    window.location.reload();
  };

  normalizeIdRedirect = (isTransfer, item) => isTransfer ? item.IdFamiliaRotuladaCentroDestino : item.IdFamiliaRotuladaCentroOrigem;

  verificaSeDeveDesabilitarBotaoRedirecionamentoPedidoTransferencia = (
    item
  ) => {
    return !(
      item.Tipo === PEDIDO_TRANSFERENCIA &&
      item.TipoUnidadeNegocioOrigem === TIPO_UNIDADE_NEGOCIO_AMBEV &&
      (item.IdFamiliaRotuladaCentroDestino !== null ||
        item.IdFamiliaRotuladaCentroOrigem !== null)
    );
  };

  orderIsAllowedToChange = (intStatus, statusTipoNegociacao) => {
    const status = this.allowedToChangeByStockStatus(intStatus);
    const tipoNegociacao = this.allowedToChangeByNegotiationStatus(statusTipoNegociacao)
    return status || tipoNegociacao;
  };

  allowedToChangeByStockStatus = (status) => {
    const value =
      !this.props.isOrderBulk &&
      (status <= StatusPedido.AguardandoAvisoRecebimento ||
        status === StatusPedido.AguardandoFornecimento ||
        status === StatusPedido.AguardandoPcSap);
    return !value;
  }

  isDisableWhenOrderIsNotAwaitingLoadComposition = (item) => {
    return item?.IdComposicao || item?.IntStatus !== StatusPedido.AguardandoComposicaoCarga;
  }

  allowedToChangeByNegotiationStatus = (tipoNegociacao) => {
    const value =
      (tipoNegociacao == TipoNegociacaoPedido.InboundDelivery ||
        tipoNegociacao == TipoNegociacaoPedido.OrderInTransit);
    return value;
  };

  verificarStatusCancelamentoSaldoPedidoVolume = (item) => {
    return (
      item.IntStatus == STATUS_VOLUME.VOLUME_ENVIADO_PARA_TRANSPORTE ||
      item.IntStatus ==
      STATUS_VOLUME.VOLUME_AGENDADO_PARA_ENVIAR_PARA_TRANSPORTE
    );
  };

  habilitarCancelamentoSaldoPedidoVolume = (item) => {
    return !!(this.verificarStatusCancelamentoSaldoPedidoVolume(item) && !(this.perfilUsuarioAmbev()
      && (this.perfilPciSolutions() ||
        profileAdminOrMasterData() ||
        this.perfilPcpUnidade() ||
        this.perfilGestorSolutions())
    )
    )
  };

  normalizeWmsTransactionId = (item) => item.WmsTransactionId ? item.WmsTransactionId : "-"

  valueWmsTransactionIdOrPO = (item) => isScheduleAgreementFlow() ? this.normalizeWmsTransactionId(item) : item.CodigoPedidoSAP

  perfilPciSolutions = () =>
    Session.get().PerfilAcesso === PERFIS_ACESSO.PERFIL_PCI_SOLUTIONS;
  perfilPcpUnidade = () =>
    Session.get().PerfilAcesso === PERFIS_ACESSO.PERFIL_PCP_UNIDADE;
  perfilGestorSolutions = () =>
    Session.get().PerfilAcesso === PERFIS_ACESSO.PERFIL_GESTOR_SOLUTIONS;

  perfilUsuarioAmbev = () => Session.get().TipoUsuario === PERFIL_USUARIO_AMBEV;

  validateStatusSigla = item => item.StatusSigla === "CN" || item.StatusSigla === "TL";

  alertLoadCompositionSuggestionsListHeader = item => this.validateStatusSigla(item) ? item.StatusSigla : "";

  identificadorDeadlineControl = (isTransfer, isOrder, isOrderBulk, item) =>
    (isTransfer
      ? "pedido-transferencia-"
      : isOrder
        ? "pedido-"
        : isOrderBulk
          ? "volume-"
          : "") + item.Id;

  getTitleButtonPedidoTranferencia = isTransfer => isTransfer ? intl.get("stocks.suggestionsListItem.goDestination") : intl.get("stocks.suggestionsListItem.goOrigin");

  isDisableCancelOrderButton = (perfilUsuario, statusPedido) => {
    return !(usuarioPodeCancelarPedido(perfilUsuario, statusPedido)
      && !this.props.isNewFlow
      && isLoadCompositionFlowSupplierSide()
      || statusPedido === StatusPedido.AguardandoComposicaoCarga);
  }

  isDisableLoadCompositionPlus = (loadCompositionId, intStatus) => {
    const orderStatusToDisableButton = Object.values(OrderStatusThatDisableLoadCompPlus).find(x => x === intStatus);
    return loadCompositionId || orderStatusToDisableButton;
  }

  render() {
    const { itemsExpanded, checked, incotermOptions } = this.state;
    const {
      cancelarSaldoPedidoVolume,
      showCancelConfirm,
      showFormOrder,
      changeValueDeadline,
      changeValueOrder,
      item,
      isOrder,
      isOrderBulk,
      isTransfer,
      saveOrder,
      isHourlyPlan
    } = this.props;
    const perfilUsuario = Session.get().PerfilAcesso,
      displayType = itemsExpanded ? "block" : "none",
      classNameSuggestionsList = itemsExpanded ? "item-expanded" : "";

    return (
      <StyledSuggestionsListItem
        className={classNameSuggestionsList}
      >
        <SuggestionsListHeader
          isItem
          hasIcon
          isOrder={true}
          itemsExpanded={itemsExpanded}
          checked={checked}
          idCheckbox={item.Id}
          checkItem={this.checkItem}
          highlighted={this.props.highlighted}
          itemDimmed={this.props.itemDimmed}
          alertLoadComposition={this.alertLoadCompositionSuggestionsListHeader(item)}
          expandAllItems={this.expandItems}
        >
          <SuggestionsListHeaderContent
            isOrder={isOrder}
            isOrderBulk={isOrderBulk}
            item={item}
            valueWmsTransactionIdOrPO={this.valueWmsTransactionIdOrPO}
            isHourlyPlan={isHourlyPlan}
          />
        </SuggestionsListHeader>
        <div style={{ display: displayType }}>
          <Row>
            <Col xs={12}>
              <SelectBox
                label={intl.get("stocks.suggestionsListItem.material")}
                placeholder={intl.get("stocks.suggestionsListItem.material")}
                valueKey={"Id"}
                labelKey={"MaterialPesquisa"}
                value={item.Material}
                searchable
                openOnFocus
                disabled
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              {!isTransfer && (
                <SelectBox
                  label={intl.get("stocks.suggestionsListItem.origin")}
                  placeholder={intl.get("stocks.suggestionsListItem.supplier")}
                  valueKey="Id"
                  labelKey="Descricao"
                  value={item.IdFornecedor}
                  options={item.Fornecedores}
                  searchable
                  openOnFocus
                  disabled
                />
              )}
              {isTransfer && (
                <Input
                  className='input'
                  label={intl.get("stocks.suggestionsListItem.destination")}
                  htmlType="text"
                  value={item.Destino}
                  disabled
                />
              )}
            </Col>
          </Row>
          <Row>
            <Col xs={8}>
              <DeadlineControl
                TituloColeta={intl.get("stocks.suggestionsListItem.collect")}
                TituloEntrega={intl.get("stocks.suggestionsListItem.issue")}
                TransitTime={item.TransitTime}
                OnChange={(coleta, entrega) =>
                  changeValueDeadline(coleta, entrega, item)
                }
                Coleta={item.Coleta}
                DataEntregaDe={item.DataEntregaDe}
                Disabled
                Identificador={this.identificadorDeadlineControl(isTransfer, isOrder, isOrderBulk, item)}
              />
            </Col>
            {showDeliveryTime(item.HoraEntrega) && (
              <Col xs={4} style={{ marginTop: "4px" }}>
                <Input
                  label={intl.get("commons.deliveryTime")}
                  id="txtHoraEntrega"
                  name="HoraEntrega"
                  htmlType="time"
                  value={item.HoraEntrega}
                  onChange={event => changeValueOrder('HoraEntrega', event.target.value, item)}
                  disabled={this.isDisableWhenOrderIsNotAwaitingLoadComposition(item)}
                  className='input'
                />
              </Col>
            )}
          </Row>

          <Row></Row>

          <Row>
            <Col xs={4}>
              <InputNumber
                type='default'
                label={intl.get("stocks.suggestionsListItem.lotSize")}
                name="tamLote"
                value={item.QuantidadeLote}
                disabled
              />
            </Col>
            <Col xs={4}>
              <InputNumber
                id="txtQuantidade"
                type='default'
                label={intl.get("stocks.suggestionsListItem.quantity")}
                name="quantidade"
                value={item.Quantidade}
                onChange={value => changeValueOrder("Quantidade", value, item)}
                disabled={this.isDisableWhenOrderIsNotAwaitingLoadComposition(item)}
              />
            </Col>
            <Col xs={4}>
              <InputNumber
                id="txtTotal"
                type='default'
                label={
                  isOrderBulk
                    ? intl.get("stocks.suggestionsListItem.pendingTotal")
                    : intl.get("stocks.suggestionsListItem.total")
                }
                name="total"
                value={item.Total}
                onChange={value => changeValueOrder("Total", value, item)}
                disabled={this.isDisableWhenOrderIsNotAwaitingLoadComposition(item)}
              />
            </Col>
            {isOrder && (
              <React.Fragment>
                <Col xs={4}>
                  <Input
                    label={intl.get("commons.door")}
                    name="door"
                    value={item.PortaWMS}
                    disabled
                    className='input'
                  />
                </Col>
                <Col xs={4}>
                  <Input
                    label={intl.get("orders.productionLine")}
                    name="productLine"
                    value={item.LinhaDeProducao}
                    disabled
                    className='input'
                  />
                </Col>
                <Col xs={4}>
                  <Input
                    label={intl.get('orders.comments')}
                    name="comments"
                    value={item.Comentarios}
                    disabled
                    className='input'
                  />
                </Col>
              </React.Fragment>
            )}
            <Col xs={12}>
              <SelectBox
                required
                label={intl.get("stocks.suggestionsListItem.incoterm")}
                placeholder={intl.get("stocks.suggestionsListItem.incoterm")}
                name="Incoterm"
                value={item.ResponsavelEntrega}
                valueKey="Key"
                labelKey="Value"
                openOnFocus={true}
                options={incotermOptions}
                width="100%"
                disabled={this.orderIsAllowedToChange(item.IntStatus, item.NegociacaoStatus)}
                onChange={(value) =>
                  changeValueOrder("ResponsavelEntrega", value.Key, item)
                }
              />
            </Col>
          </Row>
          {isOrderBulk && (
            <Row>
              <Col xs={6}>
                <Input
                  label={intl.get("stocks.suggestionsListItem.totalRequested")}
                  name="totalSolicitado"
                  htmlType="text"
                  value={formatNumber(item.QuantidadeSolicitada)}
                  disabled
                  className='input'
                />
              </Col>
              <Col xs={6}>
                <Input
                  label={intl.get("stocks.suggestionsListItem.totalAccepted")}
                  name="totalAceito"
                  value={formatNumber(item.QuantidadeAceita)}
                  disabled
                  className='input'
                />
              </Col>
            </Row>
          )}

          {item.Tipo == PEDIDO_TRANSFERENCIA && (
            <Row>
              <label className="label-style">
                {" "}
                {intl.get(
                  "stocks.suggestionsListItem.driveTransferInformation"
                )}{" "}
              </label>
              <div className="container">
                <Row>
                  <Col xs={4}>
                    <Input
                      label={intl.get(
                        "stocks.suggestionsListItem.purchasingOrganization"
                      )}
                      name="organizacaoCompras"
                      htmlType="text"
                      value={item.OrganizacaoCompras}
                      disabled
                      maxLength={4}
                      className='input'
                    />
                  </Col>
                  <Col xs={4}>
                    <Input
                      label={intl.get("stocks.suggestionsListItem.buyerGroup")}
                      name="grupoCompradores"
                      value={item.GrupoCompradores}
                      disabled
                      maxLength={3}
                      className='input'
                    />
                  </Col>
                  <Col xs={4}>
                    <Input
                      label={intl.get("stocks.suggestionsListItem.taxCode")}
                      name="codigoImposto"
                      value={item.CodigoImposto}
                      disabled
                      maxLength={5}
                      className='input'
                    />
                  </Col>
                </Row>
              </div>
            </Row>
          )}

          {isOrderBulk && (
            <Row end="xs">
              <Col xs={12} className="table-list-footer">
                <Button
                  width="200px"
                  value={intl.get("stocks.suggestionsListItem.cancelBalance")}
                  type="danger"
                  onClick={() => cancelarSaldoPedidoVolume(item)}
                  disabled={this.habilitarCancelamentoSaldoPedidoVolume(item)}
                />
              </Col>
            </Row>
          )}

          {isOrder && (
            <Row end="xs">
              <Col xs={12}>
                <S.ButtonsList>
                  {isValidToShowInvoicesPdfFile(item.CodigoPedidoSAP, item.TipoPedido) &&
                    <div title={intl.get("commons.openPdfFile")}>
                      <Button
                        className="pdf-file-button"
                        width="50px"
                        type="secondary"
                        buttonIcon={<PdfFileIcon />}
                        onClick={() => this.openNewTabPdfPage(item.CodigoPedidoSAP)}
                      />
                    </div>
                  }
                  {item.Tipo === PEDIDO_TRANSFERENCIA && (
                    <div title={this.getTitleButtonPedidoTranferencia(isTransfer)}>
                      <Button
                        disabled={this.verificaSeDeveDesabilitarBotaoRedirecionamentoPedidoTransferencia(
                          item
                        )}
                        width="50px"
                        type="secondary"
                        buttonIcon={<IconRedirecionamentoPedidoTransferencia />}
                        onClick={() =>
                          this.goToFamiliaRotuladaCentro(this.normalizeIdRedirect(isTransfer, item), item.Id)
                        }
                      />
                    </div>
                  )}
                  <div>
                    <ButtonLoadCompositionPlus
                      isFullTruckLoad={item.Total === item.QuebraEntrega}
                      hidden={this.isDisableLoadCompositionPlus(item.IdComposicao, item.IntStatus)}
                      item={mapperItemDataForLoadCompositionModal(item, isOrder)}
                      history={this.props.history}
                    />
                  </div>
                  {item.IdComposicao &&
                    <div
                      title={intl.get(
                        "stocks.suggestionsListItem.goLoadComposition"
                      )}
                    >
                      {this.validateStatusSigla(item) ? (
                        <S.LoadCompositionButtonAlert
                          className="alert"
                          onClick={() => this.goToComposition(item)}
                        >
                          <S.ContentButton>
                            <img src={IconTruckRed} alt="" />
                          </S.ContentButton>
                        </S.LoadCompositionButtonAlert>
                      ) : (
                        <Button
                          width="50px"
                          type="secondary"
                          buttonIcon={<IconTruck />}
                          onClick={() => this.goToComposition(item)}
                        />
                      )}
                    </div>
                  }
                  <div title={intl.get("stocks.suggestionsListItem.InformChangeOrder")}>
                    <Button
                      disabled={isDisableChangeOrderButton(item)}
                      width="50px"
                      type="secondary"
                      buttonIcon={<IconCalendarScheduleRefresh />}
                      onClick={() => showFormOrder(item)}
                    />
                  </div>
                  <div
                    title={intl.get("stocks.suggestionsListItem.cancelOrder")}
                  >
                    <Button
                      id="btn-cancelar-pedido"
                      icon={IconCancelWhite}
                      disabled={this.isDisableCancelOrderButton(perfilUsuario, item.IntStatus)}
                      width="50px"
                      value=""
                      type="danger"
                      onClick={() => showCancelConfirm(item)}
                    />
                  </div>
                  <div title={intl.get("stocks.suggestionsListItem.saveOrder")}>
                    <Button
                      icon={IconSaveWhite}
                      width="50px"
                      value=""
                      className="btn-salvar-pedido"
                      type="primary"
                      disabled={this.orderIsAllowedToChange(item.IntStatus)}
                      onClick={() => saveOrder(item)}
                    />
                  </div>
                </S.ButtonsList>
              </Col>
            </Row>
          )}
        </div>
      </StyledSuggestionsListItem>
    );
  }
}

OrderListItem.propTypes = {
  item: PropTypes.object.isRequired,
  checkItem: PropTypes.func,
  expandItem: PropTypes.func,
  itemsExpanded: PropTypes.bool,
  checked: PropTypes.bool,
  unCheckItem: PropTypes.bool,
  isOrder: PropTypes.bool,
  isOrderBulk: PropTypes.bool,
  salvarSugestao: PropTypes.func,
  excluirSugestao: PropTypes.func,
  showCancelConfirm: PropTypes.func,
  showFormOrder: PropTypes.func,
  changeValueSugestao: PropTypes.func,
  changeValueDeadline: PropTypes.func,
  cancelarSaldoPedidoVolume: PropTypes.func,
  materiais: PropTypes.array,
  expanded: PropTypes.bool,
  highlighted: PropTypes.bool,
  itemDimmed: PropTypes.bool,
  itemIndex: PropTypes.number,
  itemExpanded: PropTypes.bool,
  history: PropTypes.any,
  isNewFlow: PropTypes.bool,
  changeValueOrder: PropTypes.func,
  isTransfer: PropTypes.bool,
  saveOrder: PropTypes.func,
  isHourlyPlan: PropTypes.any
};

export default withRouter(OrderListItem);
