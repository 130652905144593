import { getValueOrDefault } from "utils/custom-functions"
import { getDoorName } from "utils/doors";

export const mapperOrdersList = (data) => data.ListaPedidos ?
  data.ListaPedidos.map(x => ({ ...x, Destination: `${data.Centro} - ${data.UnidadeNegocio}` })) : []

export const mapperSuggestionsList = (data, documentTypes, doors) => data.ListaSugestoes ?
  data.ListaSugestoes.map(x => ({
    ...x,
    Destination: `${data.Centro} - ${data.UnidadeNegocio}`,
    DocumentType: x.DocumentType ? x.DocumentType : getDefaultDocumentType(documentTypes, x.Materiais, x.IdMaterial, x.IdFornecedor),
    PortaWMS: getDoorName(doors, x.PortaWMS)
  })) : []

export const getDefaultDocumentType = (documentTypes, materiais, idMaterial, idFornecedor) => {
  if(documentTypes && materiais && idFornecedor){
    const material = materiais.find((x) => x.Id === idMaterial);
    const orderTypeCode = material.Fornecedores.find((y) => y.Id === idFornecedor)?.TipoFornecimentoValue;
    return getValueOrDefault(documentTypes.find((y) => y.Default && y.OrderType === orderTypeCode), { DocumentTypeEnumValue: null }).DocumentTypeEnumValue
  }
  return null;
}

export const getDocumentTypeOptions = (documentTypes, materiais, idMaterial, idFornecedor) => {
  if(documentTypes && materiais && idFornecedor){
    const material = materiais.find((x) => x.Id === idMaterial);
    const orderTypeCode = material.Fornecedores.find((y) => y.Id === idFornecedor)?.TipoFornecimentoValue;
    return documentTypes.filter((y) => y.OrderType === orderTypeCode);
  }
  return [];
}